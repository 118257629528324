import { Cdp } from '@/services/tracardi'

export async function initCdp() {
  const appStore = useAppStore()
  const cartStore = useCartStore()
  const router = useRouter()

  await until(() => appStore.appReady).toBeTruthy()

  if (
    !(
      appStore.settings.cdpConfig?.sourceId &&
      appStore.settings.cdpConfig?.apiUrl &&
      appStore.settings.cdpConfig?.scriptUrl
    )
  ) {
    return
  }

  const cdp = new Cdp({
    sourceId: appStore.settings.cdpConfig?.sourceId,
    apiUrl: appStore.settings.cdpConfig?.apiUrl,
    scriptUrl: appStore.settings.cdpConfig?.scriptUrl
  })

  await cdp.init()

  const instance = cdp.instance!
  const track = instance.helpers.track

  router.afterEach(() => {
    track('page_view', {})
  })

  watch(
    () => cartStore.cart?.items,
    () => {
      if (!cartStore.cart?.items.length) {
        track('cart-delete', {})
        return
      }

      track('cart-changes', {
        products: cartStore.cart.items.map((item) => ({
          id: item.id,
          quantity: item.qty,
          price: item.price,
          name: item.name
        }))
      })
    }
  )

  useEcommerce().bus.on((event) => {
    if (event.name === 'showProductDetails') {
      const product = event.data.product

      track('product-details-page-view', {
        id: product.id,
        name: product.name,
        price: product.price
      })
    }

    if (event.name === 'orderCreated') {
      const { orderId, cart, customer, orderType, salePointName } = event.data

      track('checkout-started', {
        id: orderId,
        order_type: orderType,
        income: {
          value: cart.total,
          currency: 'RUB'
        },
        cost: {
          value: cart.total,
          currency: 'RUB'
        },
        value: cart.total,
        products: cart.items?.map?.((item) => ({
          id: item.id,
          name: item.name,
          price: item.price,
          quantity: item.qty,
          variant: item.size?.name
        })),
        point: salePointName,
        channel: 'loyalty',
        custom: {
          merchant_id: appStore.settings.merchantId
        }
      })

      track('identification', {
        phone: {
          main: customer.phoneNumber.replace(/\D/g, '')
        },
        firstname: customer.name
      })
    }
  })
}
