import type { Utm } from '@/types/app'
import type { Cart } from '@/types/cart'
import type { Product, ProductSize } from '@/types/catalog'
import type {
  ECommercePrice,
  ECommerceCartItem,
  ECommerceProduct,
  ECommerceOrder
} from '@/types/ecommerce'
import type { OrderType } from '@/types/order'
import type { User } from '@/types/user'

const bus = useEventBus<BusEvent>('ecommerce')

bus.on((event) => {
  if (event.name === 'showProductDetails') {
    const { product } = event.data

    const showedItem: ECommerceProduct = {
      sku: product.id,
      name: product.name,
      actualPrice: {
        fiat: [product.price, 'RUB']
      },
      ...(product.oldPrice && {
        originalPrice: {
          fiat: [product.oldPrice, 'RUB']
        }
      })
    }

    const message = {
      type: 'AppMetrica',
      event: 'showProductDetailsEvent',
      args: [showedItem]
    }

    window.parent.postMessage(message, '*')
  }

  if (event.name === 'addCartItem') {
    const { product, qty, size, addonsPrice } = event.data

    const actualPrice: ECommercePrice = {
      fiat: [size?.price || product.price, 'RUB']
    }

    if (addonsPrice) {
      actualPrice.internalComponents = [[addonsPrice, 'Добавки']]
    }

    const addedItem: ECommerceCartItem = {
      product: {
        sku: product.id,
        name: size ? `${product.name} ${size.name}` : product.name,
        actualPrice
      },
      quantity: qty,
      revenue: actualPrice
    }

    const oldPrice = size?.oldPrice || product.oldPrice

    if (oldPrice) {
      addedItem.product.originalPrice = {
        fiat: [oldPrice, 'RUB']
      }
    }

    const message = {
      type: 'AppMetrica',
      event: 'addCartItemEvent',
      args: [addedItem]
    }

    window.parent.postMessage(message, '*')
  }

  if (event.name === 'purchase') {
    const { cart, orderId } = event.data

    const order: ECommerceOrder = {
      identifier: orderId,
      cartItems: cart.items.map((item): ECommerceCartItem => {
        const actualPrice: ECommercePrice = {
          fiat: [item.total, 'RUB']
        }

        const product: ECommerceProduct = {
          sku: item.id,
          name: item.size?.name ? `${item.name} ${item.size.name}` : item.name,
          actualPrice
        }

        if (item.oldTotal) {
          product.originalPrice = {
            fiat: [item.oldTotal, 'RUB']
          }
        }

        return {
          product,
          revenue: actualPrice,
          quantity: item.qty
        }
      })
    }

    const message = {
      type: 'AppMetrica',
      event: event.name + 'Event',
      args: [order]
    }

    window.parent.postMessage(message, '*')
  }

  if (event.name === 'orderSubmit') {
    const message = {
      type: 'CdpMetrica',
      event: event.name,
      data: event.data
    }

    window.parent.postMessage(message, '*')
  }

  if (event.name === 'productDetails') {
    const message = {
      type: 'CdpMetrica',
      event: event.name,
      data: event.data
    }

    window.parent.postMessage(message, '*')
  }

  if (event.name === 'cartItemsChanges') {
    const message = {
      type: 'CdpMetrica',
      event: event.name,
      data: event.data
    }

    window.parent.postMessage(message, '*')
  }

  if (event.name === 'cartEmpty') {
    const message = {
      type: 'CdpMetrica',
      event: event.name
    }

    window.parent.postMessage(message, '*')
  }
})

export const useEcommerce = () => {
  return {
    bus
  }
}

type BusEvent =
  | ShowProductDetailsEvent
  | AddCartItemEvent
  | BeginCheckoutEvent
  | PurchaseEvent
  | ProductDetailsEvent
  | CartChangesEvent
  | CartEmptyEvent
  | OrderCreatedEvent

interface ShowProductDetailsEvent {
  name: 'showProductDetails'
  data: {
    product: Product
  }
}

interface AddCartItemEvent {
  name: 'addCartItem'
  data: {
    product: Product
    qty: number
    size?: ProductSize | null
    addonsPrice?: number
  }
}

export interface BeginCheckoutEventData {
  id: string
  income: {
    value: number
    currency: 'RUB'
  }
  cost: {
    value: number
    currency: 'RUB'
  }
  value: number
  products: {
    id: string
    name: string
    price: number
    quantity: number
  }[]
  point: string
  custom: { merchant_id: string; delivery_zone?: string; geo?: { lat: number; lon: number } }
  channel: 'loyalty'
  order_type: string
}

interface BeginCheckoutEvent {
  name: 'orderSubmit'
  data: BeginCheckoutEventData
}

interface PurchaseEvent {
  name: 'purchase'
  data: {
    orderId: string
    cart: Cart
  }
}

interface ProductDetailsEvent {
  name: 'productDetails'
  data: {
    product: {
      id: string
      name: string
      price: number
      category: string
    }
  }
}

interface CartChangesEvent {
  name: 'cartItemsChanges'
  data: {
    products: {
      id: string
      name: string
      price: number
      quantity: number
    }[]
  }
}

interface CartEmptyEvent {
  name: 'cartEmpty'
}

interface OrderCreatedEvent {
  name: 'orderCreated'
  data: {
    orderId: string | number
    orderType: OrderType['code']
    cart: Cart
    customer: User
    salePointName?: string
  }
}
