import type { Context, TrackResponse } from '@/types/tracardi'

export interface CdpConfig {
  sourceId: string
  apiUrl: string
  scriptUrl: string
}

export class Cdp {
  config: CdpConfig
  prefix = 'cdp'
  instance?: Context

  constructor(config: CdpConfig) {
    this.config = config
  }

  async init() {
    await import('./compiled.js' as string)

    window.options = {
      tracker: {
        url: {
          script: this.config.scriptUrl,
          api: this.config.apiUrl
        },
        source: {
          id: this.config.sourceId
        },
        context: {
          browser: false,
          page: true,
          session: false,
          storage: false,
          screen: false,
          performance: false
        }
      }
    }

    window.tracker.track('page_view', {}, {})

    await new Promise((resolve) => {
      window.onTracardiReady.bind((cdp) => {
        const track = cdp.helpers.track

        cdp.helpers.track = async (...args) => {
          const response = await track(...args)
          this.handleResponse(response)
          return response
        }

        this.instance = cdp
        resolve(cdp)

        setTimeout(() => {
          if (Array.isArray(cdp.context?.ux)) {
            cdp.context.ux.forEach((item, index) => {
              const [attr, value] = Object.entries(item.props)[0]
              const el = document.querySelector(`[${attr}="${value}"]`)
              if (el) {
                el.id = `${this.prefix}-${index}`
              }
            })
          }
        })
      })
    })
  }

  handleResponse(response: { data: TrackResponse }) {
    if (Array.isArray(response?.data?.ux) && response.data.ux.length > 0) {
      const els = document.querySelectorAll(`[id^="${this.prefix}-"]`)
      els.forEach((el) => el.remove())

      response.data.ux.forEach((item, index) => {
        const el = document.createElement(item.tag)
        for (const key in item.props) {
          el.setAttribute(key, item.props[key])
        }
        el.id = `${this.prefix}-${index}`
        if (item.content) {
          el.textContent = item.content
        }
        document.body.appendChild(el)
      })
    }
  }
}
